import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { IAuthState } from 'src/app/core/types/auth/auth-state';
import { IUser } from 'src/app/core/types/auth/user';
import { environment } from 'src/environments/environment';
import * as AuthActions from 'src/app/core/store/user/user.actions';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()

export class AuthService {
  constructor(private http: HttpClient, private store: Store<IAuthState>, private router: Router, private _sharedService: SharedService,) { }

  login(rut: string, clientNumber: number): Promise<IUser> {
    const requestBody = {
      I_RUT: rut,
      I_IDSAP: clientNumber
    };

    return firstValueFrom(
      this.http.post<IUser>(environment.login, JSON.stringify(requestBody))
    );
  }

  /*async reLogin(rut: string, clientNumber: number, redirection: boolean): Promise<void> {

    this.logout(false)

    this.login(rut, clientNumber)
      .then((user) => {
        
        let offertTemp: any = [];

        if (user.OFERTAS) {
          if (!Array.isArray(user.OFERTAS)) {
            offertTemp.push(user.OFERTAS);
            user.OFERTAS = offertTemp;
          }
          
          for(let item of user.OFERTAS){
  
            if (!Array.isArray(item.MATERIALES)) {
              let arrayTemp:any=[];
              arrayTemp.push(item.MATERIALES);
              item.MATERIALES=arrayTemp;
            }
  
            let listOrders=user.PEDIDOS.filter(x=>x.OFERTA==item.NUM_OFERTA)
            
            for(let material of item.MATERIALES){
              let filteredOrders = listOrders.filter(order => order.PROD_COD == material.MATERIAL);
              let sumMaterialCant = filteredOrders.reduce((total, order) => total + parseInt(order.CANT), 0);
              if(sumMaterialCant>0){
                material.CANTIDAD -= sumMaterialCant;
              }
              //let fountMaterial= listOrders.find(x=>x.PROD_COD==material.MATERIAL)
               //if (fountMaterial) {
              // }
            }
          }
        } else {
          user.OFERTAS = [];
        }

        this.store.dispatch(
          AuthActions.login({
            user: {
              name: user.CLIENTE.NOMBRE_ORG,
              key: user.SESION,
              CODRETORNO: user.CODRETORNO,
              MENSAJEERROR: user.MENSAJEERROR,
              CLIENTE: { ...user.CLIENTE, COD_CLI: rut, NUM_CLI: clientNumber.toString() },
              OFERTAS: user.OFERTAS,
              SESION: user.SESION,
              PEDIDOS: user.PEDIDOS
            },
          })
        );

        if (redirection) {
          this._sharedService.setOptionTab(1);
         this.router.navigate(['/']);
        }

      }).catch((error: any) => {
        console.error('Error during login:', error);
      });
  }*/

  reLogin(rut: string, clientNumber: number, redirection: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.logout(false);
   
      this.login(rut, clientNumber)
        .then((user) => {
          let offertTemp: any = [];
   
          if (user.OFERTAS) {
            if (!Array.isArray(user.OFERTAS)) {
              offertTemp.push(user.OFERTAS);
              user.OFERTAS = offertTemp;
            }
   
            for (let item of user.OFERTAS) {
              if (!Array.isArray(item.MATERIALES)) {
                let arrayTemp: any = [];
                arrayTemp.push(item.MATERIALES);
                item.MATERIALES = arrayTemp;
              }
   
              let listOrders=user.PEDIDOS.filter(x=>x.OFERTA==item.NUM_OFERTA)
            
            for(let material of item.MATERIALES){
              let filteredOrders = listOrders.filter(order => order.PROD_COD == material.MATERIAL&& (order.ESTADO == "0" || order.ESTADO == "1" || order.ESTADO == "3" ));
              let sumMaterialCant = filteredOrders.reduce((total, order) => total + parseFloat(order.CANT), 0);
              if(sumMaterialCant>0){
                material.CANTIDAD -= sumMaterialCant;
              }
              //let fountMaterial= listOrders.find(x=>x.PROD_COD==material.MATERIAL)
               //if (fountMaterial) {
              // }
            }
          }
        } else {
            user.OFERTAS = [];
          }
   
          this.store.dispatch(
            AuthActions.login({
              user: {
                name: user.CLIENTE.NOMBRE_ORG,
                key: user.SESION,
                CODRETORNO: user.CODRETORNO,
                MENSAJEERROR: user.MENSAJEERROR,
                CLIENTE: { ...user.CLIENTE, COD_CLI: rut, NUM_CLI: clientNumber.toString() },
                OFERTAS: user.OFERTAS,
                SESION: user.SESION,
                PEDIDOS: user.PEDIDOS
              },
            })
          );
   
          if (redirection) {
            this._sharedService.setOptionTab(1);
           this.router.navigate(['/']);
          }
   
          resolve();  // Indica que la promesa se ha completado exitosamente
        })
        .catch((error: any) => {
          console.error('Error during login:', error);
          reject(error);  // Indica que la promesa ha fallado
        });
    });
  }

  logout(redirection: boolean) {
    this.store.dispatch(AuthActions.logout());
    localStorage.removeItem('authState');

    if (redirection) {
      this.router.navigate(['/auth']);
    }
  }

  setSesion(user:IUser):void{ // se crea para cambiar el string SESION ya que un pedido reservado se debe pagar con su sesion.
    this.store.dispatch(
        AuthActions.login({user:user})
    );
  }
}
