import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.sass'],
})
export class DocumentModalComponent {
  title = '';
  file?: { file: File; preview: string };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DocumentModalComponent>
  ) {
    if (data) {
      this.title = data.title;
      this.file = data.file;
    }
  }

  close() {
    this.dialogRef.close();
  }

  downloadFile() {
    if (this.file) {
      const url = URL.createObjectURL(this.file.file);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.file.file.name;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }
}
