<div class="modal">
  <div class="modal-close">
    <img
      (click)="close()"
      class="close-icon"
      src="assets/images/close.png"
      alt="close icon"
    />
  </div>
  <div class="modal-header">
    <span class="blue-text">Subir foto</span>
  </div>
  <div class="modal-body">
    <div
      class="upload"
      (drop)="onFileDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
    >
      <div class="upload-information gray-text">
        <img
          class="upload-icon"
          src="assets/images/upload.png"
          alt="upload icon"
        />
        <span class="bold">Arrastra o suelta archivos para subirlos</span>
        <span class="or">o</span>
        <button
          class="bsa-white-blue-button"
          (click)="openFileInput()"
          [disabled]="document.length > 0"
        >
          Seleccionar archivo
        </button>
      </div>
    </div>
    <input
      type="file"
      #fileInput
      accept=".jpg,.jpeg,.png,.pdf"
      style="display: none"
      (change)="onFileSelected($event)"
    />
  </div>
  <div class="information">
    <img
      class="information-icon"
      src="assets/images/info.png"
      alt="information icon"
    />
    <span class="gray-text bold"
      >Puedes adjuntar fotos con un tamaño máximo de 16 MB.</span
    >
  </div>
  <div *ngIf="document.length > 0" class="file-information">
    <div *ngFor="let image of document" class="image-item">
      <img
        class="item"
        [src]="image.preview"
        alt="{{ image.file.name }}"
        *ngIf="image.file.type === 'image'"
      />
      <img class="item" src="assets/images/pdf.png" alt="pdf icon" />
      <img
        class="remove-icon"
        src="assets/images/close-circle-fill.png"
        alt="remove icon"
        (click)="removeDocument()"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button class="bsa-white-button" (click)="close()">Volver</button>
    <button
      class="bsa-button"
      (click)="accept()"
      [disabled]="document.length == 0"
    >
      Aceptar
    </button>
  </div>
</div>
