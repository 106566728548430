import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input() tooltip: string = '';

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.style.position = 'relative';
    this.el.nativeElement.style.cursor = 'pointer';

    const tooltip = document.createElement('div');
    tooltip.style.position = 'absolute';
    tooltip.style.backgroundColor = '#00B3E3';
    tooltip.style.color = 'white';
    tooltip.style.padding = '5px';
    tooltip.style.borderRadius = '4px';
    tooltip.style.display = 'none';
    tooltip.style.zIndex = '1000';
    tooltip.style.border = '1px solid #00B3E3';
    tooltip.innerText = this.tooltip;
    tooltip.style.bottom = '125%';
    tooltip.style.width = '180px';
    tooltip.style.right = '-90px';
    this.el.nativeElement.appendChild(tooltip);

    this.el.nativeElement.onmouseenter = () => {
      tooltip.style.display = 'block';
    };

    this.el.nativeElement.onmouseleave = () => {
      tooltip.style.display = 'none';
    };
  }
}
