<div class="login">
  <div class="header background-dark-blue">
    <h1 class="title">Ingresa</h1>
  </div>
  <div class="body">
    <p class="m-0">Paga tus pedidos pendientes de forma rápida y segura.</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-24px">
      <bsa-input
        label="RUT"
        [control]="getControl('rut')"
        [controlName]="'rut'"
        type="text"
        [infoMessage]="
          'Por favor, ingrese un RUT válido (sin puntos ni guiones).'
        "
      >
      </bsa-input>
      <div class="mt-24px mb-25px">
        <bsa-input
          label="Número de cliente"
          tooltipText="Corresponde al número que se asigna una vez inscrito con el ejecutivo/a de BSA."
          [control]="getControl('clientNumber')"
          [controlName]="'clientNumber'"
          type="password"
          [infoMessage]="
            'Por favor, ingrese un número válido (sin letras ni carácteres especiales)'
          "
        >
        </bsa-input>
      </div>
      <button
        class="bsa-button mt-24px"
        [disabled]="isInvalidForm()"
        type="submit"
      >
        ingresar
      </button>
    </form>
  </div>
</div>

<ng-template #alert>
  <app-modal></app-modal>
</ng-template>
