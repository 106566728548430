import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'bsa-select',
  templateUrl: './bsa-select.component.html',
  styleUrls: ['./bsa-select.component.sass'],
})
export class BsaSelectComponent {
  @Input() control!: FormControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() showColorBorder = true;
  @Input() isAlwaysFocus = false;
  @Input() options: any[] = [];

  isFocused = false;
  isOPen = false;
  value = '';
  isMobile=false;
  @ViewChild('select') selectContainer!: ElementRef;

  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit(): void {
    document.addEventListener('click', this.onDocumentClick.bind(this));
    this.checkMobileScreen();
  }

  checkMobileScreen() {
    this.isMobile = this.deviceService.isMobile();
  }

  onDocumentClick(event: MouseEvent) {
    if (!this.selectContainer.nativeElement.contains(event.target)) {
      this.isOPen = false;
      this.isFocused = false;
    }
  }

  hasData(): boolean {
    return this.control && this.control.value && this.control.value.length > 0;
  }

  focusOnInput(): void {
    if (this.control.disabled) return;
    this.isFocused = true;
    this.isOPen = true;
  }

  focusOutInput(): void {
    this.isFocused = false;
  }

  toggleSelect(): void {
    if (this.control.disabled) return;
    this.isOPen = !this.isOPen;
  }

  getIconClass() {
    return {
      closed: !this.isOPen,
    };
  }

  selectOption(option: any): void {
    this.control.setValue(option);
    this.value = option;
    this.isOPen = false;
  }

  onChangeInput($event: any) {
    setTimeout(() => {
      this.control.setValue(this.value);
    }, 1);
  }
}
