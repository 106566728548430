let host='https://www.bsaconexion.cl/ws/'
export const environment = {
  production: false,
  apiUrl: './assets/mocks',
  front: 'https://qaspagos.azurewebsites.net/', //'http://localhost:4200', 
  login: `${host}/vce_login_qa.aspx`,
  setOrders:`${host}/vce_pedidos_h_set_qa.aspx`,
  setTransacion:`${host}/vce_settransaccion_qa.aspx`,
  setContract:`${host}/vce_setcontrato_qa.aspx`,
  dateDisabled:`${host}/vce_fecha_nd_qa.aspx`,
  sendEmail: `${host}vce_sendmail_qa.aspx`,
  encuesta: `${host}vce_setsatisfaccion_qa.aspx`,

  //PayService
  urlBaseGetNet : 'https://checkout.test.getnet.cl', // Asigna la URL base
  loginGetNet : '7ffbb7bf1f7361b1200b2e8d74e1d76f',// Asigna el login
  secretKeyGetNet : 'SnZP3D63n3I9dH9O', // Asigna la clave secreta
  //Fin PayService 
};