import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.sass'],
})
export class UploadFileModalComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;

  document: { file: File; preview: string }[] = [];

  constructor(public dialogRef: MatDialogRef<UploadFileModalComponent>) {}

  close() {
    this.dialogRef.close({
      result: false,
    });
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.isValidFile(file)) continue;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.document.push({ file, preview: e.target.result });
      };
      reader.readAsDataURL(file);
    }

    this.fileInput.nativeElement.value = '';
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
    const fileNameParts = file.name.split('.');
    const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;
    return allowedExtensions.includes(fileExtension.toLowerCase());
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  accept() {
    this.dialogRef.close({
      result: true,
      document: this.document.length > 0 ? this.document[0] : null,
    });
  }

  removeDocument() {
    this.document = [];
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.uploadFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  uploadFiles(files: FileList) {
    this.onFileSelected({ target: { files } });
  }
}
