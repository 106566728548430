import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'bsa-input',
  templateUrl: './bsa-input.component.html',
  styleUrls: ['./bsa-input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BsaInputComponent),
      multi: true,
    },
  ],
})
export class BsaInputComponent implements ControlValueAccessor {
  @Input() control!: FormControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() tooltipText: string = '';
  @Input() type = 'text';
  @Input() infoMessage!: string;
  @Input() showColorBorder = true;
  @Input() isAlwaysFocus = false;
  @Input() textPlaceholder: string = '';

  isFocused = false;
  isPassword = false;
  isPasswordVisible = false;

  ngOnInit(): void {
    if (this.hasData()) this.isFocused = true;
    this.isPassword = this.type === 'password';
  }

  focusOnInput(): void {
    this.isFocused = true;
  }

  focusOutInput(): void {
    this.isFocused = false;
  }

  hasError(): boolean {
    return (
      this.control.invalid &&
      (this.control.dirty || this.control.touched) &&
      this.showColorBorder
    );
  }

  hasData(): boolean {
    return this.control && this.control.value && this.control.value.length > 0;
  }

  isValid(): boolean {
    return this.control.valid && this.control.touched && this.showColorBorder;
  }

  writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.control.markAsTouched();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  isPasswordType(): boolean {
    return this.isPassword;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.type = this.isPasswordVisible ? 'text' : 'password';
  }
}
