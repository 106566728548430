<div class="modal">
  <div class="modal-close">
    <img
      (click)="close()"
      class="close-icon"
      src="assets/images/close.png"
      alt="close icon"
    />
  </div>
  <div class="modal-header">
    <img class="icon" [src]="assetPath" alt="information icon" />
    <span class="blue-text">{{ title }}</span>
  </div>
  <div class="modal-body">
    <div>
      <span
        *ngFor="let text of arrayText; let index = index"
        class="gray-text"
        [ngClass]="{ bold: index % 2 != 0 }"
      >
        {{ text }}</span
      >
    </div>
    <div [innerHTML]="html">
      
    </div>
    <div class="upload" *ngIf="requestDocument">
      <span class="gray-text">Si tienes el documento, </span
      ><span class="link bold" (click)="openFileInput()">adjuntar aquí.</span>
    </div>
    <input
      type="file"
      #fileInput
      accept=".jpg,.jpeg,.png,.pdf"
      style="display: none"
      (change)="onFileSelected($event)"
    />
  </div>

  <div class="modal-footer">
    <button
      *ngIf="hasSecondaryButton"
      class="bsa-white-button"
      (click)="close()"
    >
      Volver
    </button>
    <button *ngIf="hasPrimaryButton" class="bsa-button" (click)="accept()">
      Aceptar
    </button>
  </div>
</div>
<ng-template #uploadModal>
  <upload-file-modal></upload-file-modal>
</ng-template>
