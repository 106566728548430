import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { userReducer } from './core/store/user/user.reducers';
import { metaReducers } from './localStorageSync';
import { SharedModule } from "./shared/shared.module";

@NgModule({
    declarations: [AppComponent],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({
            authState: userReducer,
        }, { metaReducers }),
        SharedModule
    ]
})
export class AppModule {}
