<div class="modal">
  <div class="modal-close">
    <img
      (click)="close()"
      class="close-icon"
      src="assets/images/close.png"
      alt="close icon"
    />
  </div>
  <div class="modal-header">
    <span class="blue-text">{{ title }}</span>
  </div>
  <div class="modal-body">
    <div class="document" *ngIf="file?.file?.type?.indexOf('image') !== -1">
      <img [src]="file?.preview" />
    </div>
    <div
      class="document"
      *ngIf="file && file.preview && file!.file.type === 'application/pdf'"
    >
      <pdf-viewer
        [src]="file.preview"
        [zoom-scale]="'page-fit'"
        [original-size]="false"
        [render-text]="true"
        class="pdf-viewer"
      ></pdf-viewer>
    </div>
  </div>

  <div class="modal-footer">
    <button class="bsa-white-button" (click)="close()">Volver</button>
    <button class="bsa-button" (click)="downloadFile()">Descargar</button>
  </div>
</div>
