<div class="nav-bar background-blue">
  <img src={{urlLogo}} alt="logo" class="logo" style="cursor: pointer;" (click)="goHome()"/>
  <div class="nav-bar__links" *ngIf="showMenu">
    <img src="assets/images/phone.png" alt="phone" class="phone" style="cursor: pointer;" (click)="modalConfirmPhone()"/>
    <div class="dropdown">
      <img src="assets/images/user.png" alt="user" class="user" style="cursor: pointer;" (click)="toggleDropdown()" />
      <div class="dropdown-content" >
        <a (click)="modalLogout()" style="cursor: pointer;">Cerrar sesión</a>
      </div>
    </div>
  </div>
</div>

<ng-template #booking>
  <app-modal></app-modal>
</ng-template>
