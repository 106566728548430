import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectLoggedIn, selectOffers } from '../../store/user/user.selectors';
import { AppState } from '../../types/app-state/app-state';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.sass'],
})
export class NavBarComponent {
  showMenu = false;
  showDropdown: boolean = false;
  urlLogo = '';
  @ViewChild('booking') bookingConfirmationModal!: TemplateRef<any>;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.store.pipe(select(selectLoggedIn)).subscribe((isLoggedIn) => {
      this.showMenu = isLoggedIn;
    });
    if (!this.showMenu) {
      this.urlLogo = 'assets/images/soluciones-logo.png';
    } else {
      this.store.pipe(select(selectOffers)).subscribe((elements) => {
        if (elements) {
          let orgMain = elements[0].ORGANIZACION;
          const allEquals = elements.every(
            (item) => item.ORGANIZACION == orgMain
          );

          if (allEquals) {
            this.urlLogo = this.getUrlLogo(orgMain.toString());
          } else {
            this.urlLogo = this.getUrlLogo('a');
          }
        }
      });
    }
  }

  goHome() {
    this.router.navigate(['']);
  }

  modalConfirmPhone() {
    let modal = this.dialog.open(this.bookingConfirmationModal, {
      panelClass: 'informative-modal',
      data: {
        isSuccess: false,
        isWarning: false,
        isInformative: true,
        hasSecondaryButton: false,
        title: 'Recuerda nuestro horario de atención',
        text: ``,
        html: this.sanitizer.bypassSecurityTrustHtml(`
        <strong>Lunes a viernes:</strong> 8:00 a 18:30 hrs. <br> 
        <strong>Sábados:</strong> 09:00 a 12:55 hrs. <br> <br>
        <span style="
          color: #469bff;
          font-size: 22px;
          font-weight: 700;
        ">
        600 620 6200
        </span>
      `),
      },
    });
  }

  modalLogout() {
    let modal = this.dialog.open(this.bookingConfirmationModal, {
      panelClass: 'informative-modal',
      data: {
        isSuccess: false,
        isWarning: true,
        isInformative: false,
        hasSecondaryButton: true,
        title: 'Atención',
        text: `**¿Estás seguro de cerrar la sesión?**`,
        html: ``,
      },
    });
    modal.afterClosed().subscribe((data) => {
      if (data.result) {
        this.auth.logout(true);
      }
    });
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getUrlLogo(org: string): string {
    let result = '';
    switch (org) {
      case '3200': //Polpaico
        result = 'assets/images/logo-polpaico.png'; 
        break;
      case '5000': //BSA
        result = 'assets/images/bsa-logo.png';
        break;
      case 'a': //BSA
        result = 'assets/images/logo-a.png';
        break;
      default:
        result = 'assets/images/soluciones-logo.png';
        break;
    }

    return result;
  }
}
