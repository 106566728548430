import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectCliente, selectUser } from 'src/app/core/store/user/user.selectors';
import { AppState } from 'src/app/core/types/app-state/app-state';
import { IUser } from 'src/app/core/types/auth/user';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  optionTab! : number
  user!: IUser
  constructor(private store: Store<AppState>) { }
  
  setOptionTab(opcion:number){
    this.optionTab = opcion; 
  }
  
  getUser(){
     this.store.pipe(select(selectUser)).subscribe((data) => {
      this.user=data;
    }); 
  return this.user;
  }



}
