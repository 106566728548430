import {
  Component,
  ElementRef,
  Inject,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})
export class ModalComponent {
  isWarning = false;
  isSuccess = false;
  title = 'modal';
  arrayText = [];
  hasSecondaryButton = true;
  hasPrimaryButton = true;
  requestDocument = false;
  isInformative=false;
  assetPath = 'assets/images/warning_red.png';
  html="";
  document: { file: File; preview: string }[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef;

  @ViewChild('uploadModal') uploadModal!: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalComponent>,
    private dialog: MatDialog
  ) {
    if (data) {
      this.title = data.title;
      this.arrayText = data.text.split('**');
      this.isWarning = data.isWarning ?? false;
      this.isSuccess=data.isSuccess ?? false;
      this.isInformative=data.isInformative ?? false;
      this.hasSecondaryButton = data.hasSecondaryButton ?? true;
      this.hasPrimaryButton = data.hasPrimaryButton ?? true;
      this.requestDocument = data.requestDocument ?? false;
      this.html=data.html;
    }
    if (this.isWarning) this.assetPath = 'assets/images/warning.png';
    if (this.isSuccess) this.assetPath = 'assets/images/icon-check.svg';
    if (this.isInformative) this.assetPath = 'assets/images/icon-info.svg';
  }

  close() {
    this.dialogRef.close({
      result: false,
    });
  }

  accept() {
    this.dialogRef.close({
      result: true,
    });
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.isValidFile(file)) continue;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.document.push({ file, preview: e.target.result });
        this.dialogRef.close({
          result: true,
          document: this.document[0],
        });
      };
      reader.readAsDataURL(file);
    }

    this.fileInput.nativeElement.value = '';
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
    const fileNameParts = file.name.split('.');
    const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;
    return allowedExtensions.includes(fileExtension.toLowerCase());
  }

  openFileInput() {
    if (window.innerWidth < 1024) {
      this.fileInput.nativeElement.click();
      return;
    }
    const ref = this.dialog.open(this.uploadModal, {
      panelClass: 'upload-modal',
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }
}
