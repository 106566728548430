import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BsaDateInputComponent } from './components/bsa-date-input/bsa-date-input.component';
import { BsaInputComponent } from './components/bsa-input/bsa-input.component';
import { BsaSelectComponent } from './components/bsa-select/bsa-select.component';
import { DocumentModalComponent } from './components/document-modal/document-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { UploadFileModalComponent } from './components/upload-file-modal/upload-file-modal.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { Title } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from '../interceptors/loader/loader-interceptor.service';
  
@NgModule({
  declarations: [
    BsaInputComponent,
    ModalComponent,
    TooltipDirective,
    DocumentModalComponent,
    BsaSelectComponent,
    BsaDateInputComponent,
    UploadFileModalComponent,
    LoaderComponent,
  ],
  exports: [
    BsaInputComponent,
    ModalComponent,
    TooltipDirective,
    DocumentModalComponent,
    BsaSelectComponent,
    BsaDateInputComponent,
    UploadFileModalComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    PdfViewerModule,
  ],
  providers: [
      Title,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptorService,
        multi: true,
      }
      ,{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
})
export class SharedModule {}
