import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from '../modules/auth/services/auth.service';
import { AuthModule } from '../modules/auth/auth.module';

@NgModule({
  declarations: [LayoutComponent, NavBarComponent],
  imports: [CommonModule, RouterModule, CoreRoutingModule,SharedModule,AuthModule],
  providers: [AuthService],
})


export class CoreModule {}
