import { createSelector } from '@ngrx/store';
import { AppState } from '../../types/app-state/app-state';

export const selectFeature = (state: AppState) => state.authState;

export const selectLoggedIn = createSelector(
  selectFeature,
  (state) => state.isLoggedIn
);

export const selectUser = createSelector(selectFeature, (state) => state.user);

export const selectName = createSelector(selectUser, (user) => user.name);

export const selectOrders = createSelector(selectUser, (user) => user.PEDIDOS);

export const selectOffers = createSelector(selectUser, (user) => user.OFERTAS);

export const selectCliente = createSelector(selectUser, (user) => user.CLIENTE);

export const selectSesion = createSelector(selectUser, (user) => user.SESION);
