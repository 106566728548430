<div
  #select
  class="input-group"
  [ngClass]="{
    focus: isFocused || hasData() || isAlwaysFocus || isOPen,
  }"
>
  <div class="label">
    <span>{{ label }}</span>
  </div>
  <div class="input-container">
    <input
      class="border-dark-blue"
      type="text"
      [formControl]="control"
      (keydown)="onChangeInput($event)"
      (focus)="focusOnInput()"
      readonly
    />
    <div class="icon">
      <img
        src="assets/images/arrow_dark_blue.png"
        alt="arrow"
        [ngClass]="getIconClass()"
        (click)="toggleSelect()"
      />
    </div>
  </div>
  <div *ngIf="isOPen" class="dropdown">
    <div class="dropdown-content">
      <div
        *ngFor="let option of options"
        class="option"
        (click)="selectOption(option)"
      >
        <span>{{ option }}</span>
      </div>
    </div>
  </div>
</div>
