import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'bsa-date-input',
  templateUrl: './bsa-date-input.component.html',
  styleUrls: ['./bsa-date-input.component.sass'],
})
export class BsaDateInputComponent {
  @Input() control!: FormControl;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() showColorBorder = true;
  @Input() isAlwaysFocus = false;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Input() isfocusCalendar = true;
  @Input() disableDates: Date[] | null=null;

  isFocused = false;
  isOPen = false;
  selected: Date | null = null;

  @ViewChild('select') selectContainer!: ElementRef;

  isMobile=false;
  isTabled=false;
  isDesktop=false;
  constructor(private deviceDetector:DeviceDetectorService) {}

  ngOnInit(): void {
    document.addEventListener('click', this.onDocumentClick.bind(this));
    this.isMobile=this.deviceDetector.isMobile();
    this.isTabled=this.deviceDetector.isTablet();
    this.isDesktop=this.deviceDetector.isDesktop();
  }


  onDocumentClick(event: MouseEvent) {
    if (!this.selectContainer.nativeElement.contains(event.target)) {
      this.isOPen = false;
      this.isFocused = false;
    }
  }

  hasData(): boolean {
    return this.control && this.control.value;
  }

  focusOnInput(): void {
    this.isFocused = true;
    this.isOPen =this.isfocusCalendar;
  }

  focusOutInput(): void {
    this.isFocused = false;
  }

  toggleSelect(): void {
      this.isOPen = !this.isOPen;
  }

  getIconClass() {
    return {
      closed: !this.isOPen,
    };
  }

  myFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    const day = date.getDay();
    const isSunday = day === 0;
    const isAfterMinDate = this.minDate ? date >= this.minDate : true;
    const isBeforeMaxDate = this.maxDate ? date <= this.maxDate : true;

    if (this.disableDates != null) {
      // Verifica si la fecha está en el array de fechas a deshabilitar
      const isDisabledDate = this.disableDates.some(disableDate =>
        date.getFullYear() === disableDate.getFullYear() &&
        date.getMonth() === disableDate.getMonth() &&
        date.getDate() === disableDate.getDate()
      );
      return !isSunday && isAfterMinDate && isBeforeMaxDate && !isDisabledDate;
    }
    
    return !isSunday && isAfterMinDate && isBeforeMaxDate;
  };

  onDateChange(event: any) {
    this.control.setValue(event);
    setTimeout(() => {
      this.focusOutInput();
      this.isOPen = false;
    }, 50);
  }

  get isFocusedInput(): boolean {
    return (
      this.isFocused || this.isOPen || this.hasData() || this.isAlwaysFocus
    );
  }

  calendarClicked(event: MouseEvent) {
    event.stopPropagation();
  }
}
