<div
  #select
  class="input-group"
  [ngClass]="{
    focus: isFocusedInput,
  }"
  
>
  <div class="label">
    <span>{{ label }}</span>
  </div>
  <div class="input-container"  (click)="toggleSelect()">
    <input
      class="border-dark-blue"
      type="text"
      [value]="control.value | date : 'yyyy-MM-dd'"
      (focus)="focusOnInput()"
      readonly
    />
    <div class="icon">
      <img
        *ngIf="!isFocusedInput"
        src="assets/images/calendar_white_blue.png"
        alt="calendar"
        
      />
      <img
        *ngIf="isFocusedInput"
        src="assets/images/calendar_blue.png"
        alt="calendar"
      />
    </div>
  </div>
  <mat-card
    *ngIf="isOPen"
    class="input-calendar"
    (click)="calendarClicked($event)"
  >
    <mat-calendar
      [(selected)]="selected"
      [dateFilter]="myFilter"
      (selectedChange)="onDateChange($event)"
    ></mat-calendar>
  </mat-card>
</div>
