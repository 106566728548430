<div
  class="input-group"
  [ngClass]="{
    focus: isFocused || hasData() || isAlwaysFocus,
    error: hasError(),
    good: isValid()
  }"
>
  <div class="label">
    <span>{{ label }}</span>
    <div *ngIf="tooltipText != ''" [tooltip]="tooltipText" class="info-icon">
      <img src="assets/images/info_2.png" alt="information icon" />
    </div>
  </div>
  <div class="input-container">
    <input
      class="border-dark-blue"
      [type]="type"
      [formControl]="control"
      (focus)="focusOnInput()"
      (blur)="focusOutInput()"
      [placeholder]="textPlaceholder"
    />
    <div class="icon" *ngIf="isPasswordType()">
      <img
        *ngIf="isPasswordVisible"
        src="assets/images/visibility.png"
        alt="eye"
        (click)="togglePasswordVisibility()"
      />
      <img
        *ngIf="!isPasswordVisible"
        src="assets/images/visibility_off.png"
        alt="eye"
        (click)="togglePasswordVisibility()"
      />
    </div>
  </div>

  <div class="info">
    {{ infoMessage }}
  </div>
</div>
