import { createReducer, on } from '@ngrx/store';
import { IUser } from '../../types/auth/user';
import { login, logout } from './user.actions';

export const initialState = {
  isLoggedIn: false,
  user: null as IUser | null,
};

export const userReducer = createReducer(
  initialState,
  on(login, (state, action) => ({
    ...state,
    isLoggedIn: true,
    user: action.user,
  })),
  on(logout, (state) => ({ ...state, isLoggedIn: false, user: null }))
);
