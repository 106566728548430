import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AuthActions from 'src/app/core/store/user/user.actions';
import { IAuthState } from 'src/app/core/types/auth/auth-state';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent {
  form!: FormGroup;
  rut: FormControl = new FormControl('');
  clientNumber: FormControl = new FormControl('');
  isFocused = false;

  @ViewChild('alert') alertModal!: TemplateRef<any>;
  constructor(
    private store: Store<IAuthState>,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      rut: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{8,}[0-9kK]{1}$/),
      ]),
      clientNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
      ]),
    });
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.form.get(controlName);
    const isInvalid = control?.invalid && control?.touched;
    return isInvalid || false;
  }

  onSubmit(): void {
    if (this.isInvalidForm()) return;
    const cleanedValue = this.form.controls['rut'].value.replace(/-/g, '');
    const formattedValueRut = cleanedValue.slice(0, -1) + '-' + cleanedValue.slice(-1);
    
    this.authService
      .login(formattedValueRut,  this.form.controls['clientNumber'].value)
      .then((user) => {
        
        if (user.SESION) {

          let offertTemp:any=[];

          if (user.OFERTAS) {
            if (!Array.isArray(user.OFERTAS)) {
              offertTemp.push(user.OFERTAS);
              user.OFERTAS=offertTemp;
            }
            
            for(let item of user.OFERTAS){
  
              if (!Array.isArray(item.MATERIALES)) {
                let arrayTemp:any=[];
                arrayTemp.push(item.MATERIALES);
                item.MATERIALES=arrayTemp;
              }
    
              let listOrders=user.PEDIDOS.filter(x=>x.OFERTA==item.NUM_OFERTA)
              
              for(let material of item.MATERIALES){
                let filteredOrders = listOrders.filter(order => order.PROD_COD == material.MATERIAL && (order.ESTADO == "0" || order.ESTADO == "1" || order.ESTADO == "3" ));
                let sumMaterialCant = filteredOrders.reduce((total, order) => total + parseFloat(order.CANT), 0);
                if(sumMaterialCant>0){
                  material.CANTIDAD -= sumMaterialCant;
                }
                //let fountMaterial= listOrders.find(x=>x.PROD_COD==material.MATERIAL)
                 //if (fountMaterial) {
                // }
              }
            }
          }else{
            user.OFERTAS=[];
          }
          
  
          this.store.dispatch(
            AuthActions.login({
              user: {
                name: user.CLIENTE.NOMBRE_ORG,
                key: user.SESION,
                CODRETORNO: user.CODRETORNO,
                MENSAJEERROR: user.MENSAJEERROR,
                CLIENTE: {...user.CLIENTE, COD_CLI:formattedValueRut, NUM_CLI:this.form.controls['clientNumber'].value},
                OFERTAS: user.OFERTAS,
                SESION: user.SESION,
                PEDIDOS:user.PEDIDOS
              },
            })
          );
          this.router.navigate(['/']);
        }else{
          this.modalInvalidUser();
        }
        
      }).catch((error: any) => {
        console.error('Error during login:', error);
      });
  }

  modalInvalidUser(){
      let modal = this.dialog.open(this.alertModal, {
        panelClass: 'informative-modal',
        data: {
          isSuccess: false,
          isWarning:false,
          isInformative:false,
          hasSecondaryButton:false,
          title: 'Atención',
          text: `**Usuario o contraseña incorrecta.**`,
          html:``
        },
      });
  }
  

  isInvalidForm(): boolean {
    return this.form.invalid;
  }

  focusOnInput(): void {
    this.isFocused = true;
  }

  focusOutInput(): void {
    this.isFocused = false;
  }

  getControl(controlName: string): FormControl {
    return this.form.get(controlName) as FormControl;
  }
}
