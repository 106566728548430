import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectLoggedIn } from '../store/user/user.selectors';
import { IAuthState } from '../types/auth/auth-state';

export const authGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  const store = inject(Store<IAuthState>);
  const router = inject(Router);

  let isLoggedIn = await firstValueFrom(store.pipe(select(selectLoggedIn)));
  let isAuthPage = route.routeConfig?.path?.includes('auth') || false;

  if (isAuthPage && isLoggedIn) {
    router.navigate(['/']);
    return !isLoggedIn;
  }

  if (!isLoggedIn && !isAuthPage) {
    router.navigate(['/auth']);
    return false;
  }

  return true;
};
